@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

/* .outerContainer{
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url('./images/qr-generator-bg.jpeg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 40px;
} */

.outerContainer {
  height: 90vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 40px;
  overflow: hidden; 
}

.outerContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1; /* Optional: darkens the video like your gradient */
}


.outerContainer h1{
    color: #ffffff;
    font-size: 25px;
    text-align: center;
}

.outerContainer .box{
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    background: rgba(255, 255, 255, 0.306); /* Semi-transparent white background */
    border-radius: 15px;
    backdrop-filter: blur(8x); /* Blur effect */
    -webkit-backdrop-filter: blur(5px); /* For Safari support */
    box-shadow: 0 4px 30px rgba(8, 8, 0, 0.1);
}


.outerContainer .box .form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.outerContainer .box .form label{
    color: #ffffff;
    font-size: 14px;
    text-align: center;
}

.outerContainer .box .form input{
    outline: none;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2); 
    border-radius: 5px;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    border: none;
    color: #ffffff;
}

.outerContainer .box .form input::placeholder{
    color: #ffffff;
    text-align: center;
}

.outerContainer .box .form button{
    background-color: rgb(0, 59, 236);
    padding: 15px;
    border-radius: 5px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}


.outerContainer .box .form button:hover{
    background-color: rgb(44, 44, 255);
}


.qrImgBox{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.downloadBtn{
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  color: aqua;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.qrImage{
  width: 170px;
  margin: auto;
  border-radius: 5px;
}

footer{
    width: 100%;
    height: 10vh;
    background-color: rgb(44, 44, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}


footer h2 a{
    text-decoration: none;
    font-size: 14px;
    color: #ffffff
}

@media (max-width: 575.98px) {
    .outerContainer{
        padding: 60px 30px;
    }
    .outerContainer .box{
        width: 100%;
    }
}
